import { AtsLabel } from "./AtsLabel";
import { ExperienceEntry } from "./ResumeData"


type Props = {
   data: ExperienceEntry;
}

export const Experience = ({
   data,
}: Props) => {

   const { title, company, timeRangeDisplay: timeRange, years, months, summaryBullets, isCompact, skills } = data;

   const yearLabel = years === 1 ? "year" : "years";
   const monthLabel = months === 1 ? "month" : "months";

   return (
      <div className="experienceEntry">
         <div>
            <h3>
               <AtsLabel title="Role Title: " />
               {title}
            </h3>
            <h4>
               <span>
                  <AtsLabel title="Company: " />
                  {company}
               </span>
               <span className="totalTime">
                  <span className="timeRange">
                     {years > 0 &&
                        <>
                           <span>{years}&nbsp;</span>
                           <small>{yearLabel}&nbsp;</small>
                        </>
                     }
                     {months > 0 &&
                        <>
                           <span> {months}&nbsp;</span>
                           <small>{monthLabel}&nbsp;</small>
                        </>
                     }
                  </span>
                  {isCompact &&
                     <small>({timeRange})</small>
                  }
               </span>
               {!isCompact &&
                  <small>({timeRange})</small>
               }
            </h4>
         </div>
         <div className="experienceSummary">
            <AtsLabel title="Summary: " />
            <ul>
               {summaryBullets.map((bullet, key) =>
                  <li key={key}>
                     {bullet}
                  </li>
               )}
            </ul>
            <small>{skills?.join(", ")}</small>
         </div>
      </div>
   )

}
