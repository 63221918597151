import { ResumeDisplayMode } from "./ResumeDisplayMode";


type Props = {
   displayMode: ResumeDisplayMode;
   setDisplayMode: (mode: ResumeDisplayMode) => void;
}

export const Controls = ({
   displayMode,
   setDisplayMode
}: Props) => {


   return (
      <div className="resumeControls" title="Display mode">
         <select
            value={displayMode}
            onChange={(e) => {
               const value = e.target.value as ResumeDisplayMode;
               setDisplayMode(value)
            }}
         >
            <option value="html">Two Column</option>
            <option value="html-for-ats">Single Column</option>
            <option value="plain-text-for-ats">ATS Text</option>
         </select>
      </div>
   )
}
