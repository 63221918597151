import { LabelledInfo } from "../components/LabeledInfo"
import { resumeData } from "./ResumeData"


export const ResumeDetailsBar = () => {

   return (
      <div className="resumeDetailsBar">
         <LabelledInfo title="Location" value={resumeData.location} />
         <LabelledInfo title="Education" value={resumeData.educationShort} />
         {resumeData.languages &&
            <LabelledInfo title="Languages" value={resumeData.languages} />
         }
      </div>
   )
}
