import { resumeData } from "./ResumeData"


const CRLF = "\r\n"

const dateFormatter = new Intl.DateTimeFormat("en-US", {
   year: "numeric",
   month: "long",
});

export const ResumeATS = () => {

   const plainTextHeading = `
Name: ${resumeData.fullName}
Summary: ${resumeData.professionalSummary}
Location: ${resumeData.location}
Education: ${resumeData.educationLong}
Languages: ${resumeData.languages}`

   const plainTextExperience = resumeData.experience.map((experienceEntry, key) =>
      `Work Experience ${key + 1}:
Job Title: ${experienceEntry.title}
Company: ${experienceEntry.company}
From: ${dateFormatter.format(experienceEntry.startDate)}
To: ${dateFormatter.format(experienceEntry.endDate)}
Role Description: ${experienceEntry.summaryBullets.join("\n")}\n${experienceEntry.skills?.join(", ")}`
   )

   const skills = resumeData.skills.concat(resumeData.skillsAts).join(", ")

   const combined = `
${plainTextHeading}

Experience:

${plainTextExperience.join(CRLF + CRLF)}

Skills: ${skills}
   `

   return (
      <div className="resume">
         <pre className="resumePlainText">
            {combined}
         </pre>

      </div>

   )
}
