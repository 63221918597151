
export type ExperienceEntry = {
   title: string;
   company: string;
   timeRangeDisplay: string;
   startDate: Date;
   endDate: Date;
   years: number;
   months: number;
   summaryBullets: string[];
   isCompact?: boolean;
   skills?: string[];
}

export type ResumeData = {
   fullName: string;
   professionalSummary: string;
   location: string;
   educationShort: string;
   educationLong: string;
   languages?: string;
   experience: ExperienceEntry[];
   /** Skills that should be more front and center */
   skills: string[];
   /** Additional Keywords to help ATS systems flag the resume */
   skillsAts: string[];
}


export const resumeData: ResumeData = {
   fullName: "Todd B Fisher",
   professionalSummary:
      `Over 15 years of experience in software engineering and leadership, with a strong focus on fostering a healthy work culture that drives sustainable business value.`,
   location: "Salt Lake City, UT",
   educationShort: "BS In Information Systems @ SUU (2009)",
   educationLong: "BS In Information Systems @ Southern Utah University (2009)",
   languages: "English, Portuguese",
   experience: [
      {
         title: "Founding CTO", // / Lead Software Engineer", // Chief Technology Officer", // / Lead Software Engineer",
         company: "Verdi Ai",
         timeRangeDisplay: "Feb 2023 - July 2024",
         startDate: new Date("2023-02-01"),
         endDate: new Date("2024-07-15"),
         years: 1,
         months: 6,
         summaryBullets: [
            "Built an AI driven SaaS product to help businesses make better decisions, targeting the product management space. ",
            // "Added and iterated on many product features that included making various OpenAI calls (Davinci, Gpt3 - Gpt4o, function calling, etc). ",
            // "As a hands-on CTO in a startup I was the main software engineer evolving the codebase."
         ],
         skills: [
            "OpenAI", "Node", "React", "Typescript", "AWS", "Prisma", "sql", "Redis", "ProseMirror", "Serverless Framework", "technical strategy", "product management",
         ],
      },
      {
         title: "Principal of Software Engineering Practices",
         company: "Pluralsight",
         timeRangeDisplay: "Apr 2021 - Feb 2023",
         startDate: new Date("2021-04-01"),
         endDate: new Date("2023-02-01"),
         years: 1,
         months: 11,
         summaryBullets: [
            "Led cross org initiatives to further unify engineering principles & practices, engineering KPIs, and security compliance for nearly 200 team members. ",
            "Created and taught engineering onboarding curriculum to new hires in the US, Australia and India. ",
            "Actively promoted our engineering culture by organizing hack-a-thons, discussions around practices, mob sessions, knowledge sharing, retros, individual check-ins and more. ",
         ],
         skills: [
            "1:1s with engineering leaders", "developer experience", "cross functional teams", "value delivery"
         ]
      },
      {
         title: "Acting VP of the Technology Center of Excellence",
         company: "Pluralsight",
         timeRangeDisplay: "Jan 2022 - May 2022",
         startDate: new Date("2022-01-01"),
         endDate: new Date("2022-05-01"),
         years: 0,
         months: 5,
         summaryBullets: [
            "Supported my team in efforts around accessibility, design ops, skilling-up and engineering practices, which included: ",
            "Delivering internal training on strategic topics such as Terraform and accessibility, via Pluralsight's own products. ",
            "Rolling out a design system for internal Pluralsight teams. ",
         ],
         skills: [
            "Reported directly to the CTO", "cross-org initiatives", "training" //, "design system", "WCAG"
         ]
      },
      {
         title: "Principal Software Engineer / Engineering Manager",
         company: "Pluralsight",
         timeRangeDisplay: "Aug 2019 - Apr 2021",
         startDate: new Date("2019-08-01"),
         endDate: new Date("2021-04-01"),
         years: 1,
         months: 9,
         summaryBullets: [
            "Lead and mentored engineers while contributing to team initiatives. ",
            "Refined and participated in the interview process for engineers. ",
            "Ran the engineering side of the Internship/Apprentice programs (3 yrs). ",
         ],
         skills: [
            "1:1s with engineers", "career development", "mass interviews"
         ]
      },
      {
         title: "Senior Software Engineer",
         company: "Pluralsight",
         timeRangeDisplay: "Oct 2018 - Aug 2019",
         startDate: new Date("2018-10-01"),
         endDate: new Date("2019-08-01"),
         years: 0,
         months: 11,
         summaryBullets: [
            "Developed scalable, user targeted communication tools for other teams. ",
            "Improved the user Profile by adding daily activity tracking, learning streaks and content insights. ",
         ],
         skills: [
            "AWS", ".Net", "C#", "Node", "Postgres", "React", "Typescript", "TDD", "paired programming", "CI/CD", "Kubernetes",
         ]
      },
      {
         title: "Director of Engineering / Founding Software Engineer", // / VP 
         company: "TouchMD",
         timeRangeDisplay: "2009 - 2018",
         startDate: new Date("2009-07-01"),
         endDate: new Date("2018-10-01"),
         years: 9,
         months: 4,
         isCompact: true,
         summaryBullets: [
            "Led and implemented the technical evolution of TouchMD, from early startup to a well established product. ",
            "Recruited, managed and worked alongside the engineering teams. ",
            "Built cross-platform, multilingual content management systems. ",
         ],
         skills: [
            "Multiple hats", "engineering management", "C#", "Web APIs",
            // "Entity Framework", "ASP", "WinForms", "WPF", "UWP Apps", "Xamarin", ".Net Core", "XAML",
            "Azure", "SQL Server", "AngularJS", "React", "Node", "Redis", "Docker", "Android", "iOS",
            "3rd party integrations", "HIPAA", "security", "privacy", "contracts",
            // "Azure", ".Net (C#)", "AngularJS", "SQL Server", "Windows", "Android", "iOS",
         ]
      },
      {
         title: "Adjunct Professor",
         company: "Southern Utah University",
         timeRangeDisplay: "2012 - 2016",
         startDate: new Date("2012-08-01"),
         endDate: new Date("2016-04-01"),
         years: 3,
         months: 9,
         isCompact: true,
         summaryBullets: [
            "Taught a web development course using HTML, CSS, Javascript. ",
            "Taught a course where students launched their own e-commerce site. ",
         ],
         skills: [
            "Curriculum development", "presenting", "hands-on", "mentoring"
         ]
      },
      {
         title: "Web Developer",
         company: "Freelance",
         timeRangeDisplay: "2005 - 2013",
         startDate: new Date("2005-01-01"),
         endDate: new Date("2013-01-01"),
         years: 8,
         months: 0,
         isCompact: true,
         summaryBullets: [
            "Built and managed various web development projects for clients. ",
         ],
         skills: [
            "HTML", "CSS", "JavaScript", "mySQL", "jQuery", "Photoshop", "Wordpress", "Joomla", "Flash / AS3", "PHP"
            // "Client management", "web technologies"
         ]
      },
      {
         title: "IT Technical Support",
         company: "Southern Utah University",
         timeRangeDisplay: "2006 - 2009",
         startDate: new Date("2006-01-01"),
         endDate: new Date("2009-06-01"),
         years: 3,
         months: 6,
         isCompact: true,
         summaryBullets: [
            "Maintained Computer systems for Administrative Departments and helped users with their problems. ",
         ],
         skills: [
            "Windows", "networking", "printers", "malware removal", "web browsers", "Microsoft Office", "Adobe Creative Suite", "mobile technologies"
         ]
      }
   ],
   skills: [
      "leadership", "hiring", "coaching", "collaboration", "psychological safety", "communication", "empathy", "agile", "strategy", "product management",
   ],
   skillsAts: [
      "Typescript", "Javascript", "C#", "React", "Node", "SQL", "AWS", "Azure", "OpenAI", "web", "iOS", "Android",
      "fullstack", "front-end", "back-end",
      // "mobile", "user research", "usability", "GraphQL", "REST",
      // "Redis", "Docker", "Kubernetes", "Agile",
      // "Web technologies", "frameworks", "GIT", "testing", "Jest",
      "and more"
   ],
}
