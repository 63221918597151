import { useState } from "react"
import { AtsLabelHeading } from "./AtsLabel"
import { Controls } from "./Controls"
import { Experience } from "./Experience"
import { ResumeATS } from "./ResumeATS"
import { resumeData } from "./ResumeData"
import { ResumeDetailsBar } from "./ResumeDetailsBar"
import { ResumeHeader } from "./ResumeHeader"
import { ResumeDisplayMode } from "./ResumeDisplayMode"


export const Resume = () => {

   const [displayMode, setDisplayMode] = useState<ResumeDisplayMode>("html")

   return (
      <>
         {displayMode === "plain-text-for-ats" &&
            <ResumeATS />
         }
         {displayMode !== "plain-text-for-ats" &&

            <div className={`resume ${displayMode === "html" ? "" : "atsOptimized"}`}>

               <ResumeHeader resumeData={resumeData} />

               <ResumeDetailsBar />

               <div>
                  <AtsLabelHeading title="Experience" />
                  {resumeData.experience.map((experienceEntry, key) =>
                     <Experience
                        key={key}
                        data={experienceEntry}
                     />
                  )}
               </div>

               {/* <div>
               <AtsLabelHeading title="Skills" />
               <Skills />
            </div> */}

            </div>
         }
         <Controls
            displayMode={displayMode}
            setDisplayMode={setDisplayMode}
         />

      </>
   )
}
