import { ContactInfo } from "./ContactInfo"
import { ResumeData } from "./ResumeData"


type Props = {
   resumeData: ResumeData;
}
export const ResumeHeader = ({
   resumeData
}: Props) => {


   return (
      <div className="resumeHeader">
         <div>
            <h1>
               {resumeData.fullName}
            </h1>
            <ContactInfo />
         </div>

         <div className="professionalSummary">
            {resumeData.professionalSummary}
         </div>

      </div>
   )
}
