
type Props = {
   title: string;
}
export const AtsLabel = ({
   title
}: Props) => {

   return (
      <span className="atsLabel">
         {title}
      </span>
   )
}

export const AtsLabelHeading = ({
   title
}: Props) => {

   return (
      <h2 className="atsLabel">
         {title}
      </h2>
   )
}
