

type Props = {
   title: string;
   value: string;
}

export const LabelledInfo = ({
   title,
   value
}: Props) => {
   return (
      <p className="labelledInfo">
         <span>{title}:</span>&nbsp;<span>{value}</span>&nbsp;
      </p>
   )
}
